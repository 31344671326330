import moment from 'moment';

function secondsToString(seconds) {
    const numdays = Math.floor((seconds % 31536000) / 86400); 
    const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    const days = numdays ? (numdays + (numdays > 1 ? " dias " : " dia ") ) : '';
    const hours = numhours ? (numhours + (numhours > 1 ? " horas " : " hora ")) : '';
    const minutes = numminutes ? (numminutes + (numminutes > 1 ? " minutos" : " minuto")) : '';

    const text = `${days}${hours}${minutes}`;

    if (!text) {
        return 'unos instantes';
    }

    return text;
}

const TimeAgo = ({date}) => {
    return (
        <span>{secondsToString(Math.abs(moment(date).diff(moment(), 'seconds')))}</span>
    )
}

export default TimeAgo;
