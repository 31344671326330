import React, { useState, useRef, useMemo } from 'react';

/**
 * antd components
 */
import { Select } from 'antd';

/**
 * tird-party dependencies
 */
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

/**
 * services
 */
import { search } from './search.service';

const { Option } = Select;

const ServiceSearch = ({ debounceTimeout = 1000, onChange }) => {
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const [value, setValue] = useState([]);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            if (!value.trim()) {
                return;
            }

            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);

            search(value).then(newOptions => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions.map(option => {
                    return <Option key={option._id}>{option.numero_solicitud_fisica}</Option>
                }));
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [search, debounceTimeout]);

    return (
        <Select
            mode='multiple'
            showSearch
            value={value}
            placeholder="Escribe el numero de solicitud"
            style={{ width: '100%' }}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={debounceFetcher}
            onChange={(value) => {
                setValue(value);
                onChange(value);
            }}
            notFoundContent={null}
        >
            {options}
        </Select>
    )
}

ServiceSearch.propTypes = {
    /**
     * time to wait between user types before trigger a new http call
     */
    debounceTimeout: PropTypes.number,
    /**
     * event triggered when the selected values change
     */
    onChange: PropTypes.func.isRequired
};

ServiceSearch.defaultProps = {
    debounceTimeout: 1000,
};

export default ServiceSearch;