import axios from '../../lib/promessa-axios';

export function getProfile() {
    return axios.get('/profile')
        .then(response => {
            return response.data.data});
}

export function updateProfile({ apaterno, amaterno, nombre, accountantEmail }) {
    return axios.post('/profile', {
        apaterno,
        amaterno,
        nombre,
        accountantEmail,
    });
}
