import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Nueva from "./nueva/index";

const devolucion = () => {
  return (
    <Router>
      <Switch>
        <Route path="/comercial/produccion/devolucion/nueva" exact>
          <Nueva />
        </Route>
        <Route
          path="/comercial/produccion/devolucion/editar/:id"
          exact
          component={Nueva}
        ></Route>
      </Switch>
    </Router>
  );
};

export default devolucion;