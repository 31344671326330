import axios from '../../lib/promessa-axios';

export function getConcursos(year, title, status, pageOffset, pageSize) {
    return axios.get(`/concursos/`, {
        params: {
            year,
            title,
            status,
            pageOffset,
            pageSize,
        }
    });
}