import axios from '../../../../lib/promessa-axios';

export function getCredentials() {
    return axios.get('/monitoring/issuance-credentials').then(response =>{
        return response.data.credentials;
    });
}

export function associateCredentialsWithUser(credentialsId, userId) {
    return axios.put(`/monitoring/issuance-credentials/${credentialsId}`, {
        id_usuario: userId
    });
}

export function createCredentials(name) {
    return axios.post('/monitoring/issuance-credentials', {
        name,
    });
}

export function deleteCredentials(id) {
    return axios.delete(`/monitoring/issuance-credentials/${id}`);
}
