import React from 'react';
import { WarningFilled } from '@ant-design/icons';

const Forbidden = () => {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col text-right'>          
            <h1 className='display-1 text-warning'>403</h1>
          </div>
          <div className='col'>
            <div className='row'>
              <div className='col-1 text-left'>
                <h3>
                <WarningFilled style={{ color: '#FFA800', fontSize: '30px' }} />{''}
                </h3>
              </div>
              <div className='col text-left'>
                <h1>No tiene permitido ejecutar esta acción.</h1>
              </div>
            </div>
            <p>
              El error anterior se produjo mientras el servidor estaba
              procesando su solicitud.
            </p>
            <p>
              Si cree que se trata de un error de servidor. Póngase en contacto
              con nosotros, Gracias.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forbidden;