import axios from 'axios';
import { notification } from 'antd';
import { Parser } from 'html-to-react';

const parser = new Parser();

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_PROMESSA_BASE_URL || 'http://localhost'}/api/v1`,
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => {
    if (response.config.url.indexOf("/api/v1") < 0) {
      return response;
    }

    if (!response.data.success) {
      notification.error({
        message: `. ID: ${response.data.idLog}`,
        description: parser.parse(response.data.error || ""),
      });
    }
    return response;
  },
  function(error) {
    if (!error.response) {
      return Promise.reject(error);
    }
    
    if (error?.response?.status === 400) {
      notification.error({
        message: `Ocurrió un error al realizar la operación`,
        description: parser.parse(error?.response?.message ?? ''), // TODO pedirle a antonio un mejor mensaje
      });
    }

    return Promise.reject(error);
  }
);

export default instance;
