import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Produccion from "./produccion/index";

const comercial = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/comercial/produccion">
            <Produccion/>
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default comercial;