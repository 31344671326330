import React, { useEffect, useState } from 'react';
import { Table, Tag, Button, Modal } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { getInstanceQueueTasks, getInstances, getInstanceServices, terminateInstance, updateInstance } from '../../../services/monitoring/issuance/instances';

import TimeAgo from '../../../components/TimeAgo';
import { updateToken } from '../../../services/monitoring/issuance/token';

const colors = {
    'creating': 'geekblue',
    'running': 'green',
    'unresponsive': 'volcano',
};

const issuanceStatusNames = {
    '0': 'Pendiente',
    '1': 'Preparando ambiente',
    '2': 'Iniciando automatización',
    '3': 'Adquiriendo credenciales de Citrix',
    '4': 'Login Citrix',
    '5': 'Descargando IE',
    '6': 'Obteniendo solicitud',
    '7': 'DES Login',
    '8': 'Capturando datos del solicitante',
    '9': 'Capturando datos del plan',
    '10': 'Capturando otros datos',
    '11': 'Validando captura',
    '12': 'Interacción manual',
    '13': 'Guardando solicitud',
    '100': 'Shutting-down'
};

const Instances = () => {
    const [isLoading, setLoading] = useState(true);
    const [instances, setInstances] = useState([]);
    const [isComputingDetailsModalVisible, setIsComputingDetailsModalVisible] = useState(false);
    const [services, setServices] = useState([]);
    const [queueTasks, setQueueTasks] = useState([]);
    const [currentInstance, setCurrentInstance] = useState([]);

    const showComputingDetailsModal = () => {
        setIsComputingDetailsModalVisible(true);
    };

    const handleComputingDetailsOk = () => {
        setIsComputingDetailsModalVisible(false);
    };

    const handleComputingDetailsCancel = () => {
        setIsComputingDetailsModalVisible(false);
    };

    const loadData = () => {
        Promise.all([
            getInstances(),
        ]).then(([instances]) => {
            setInstances(instances);

            if (currentInstance) {
                setCurrentInstance(instances.find(i => i.id == currentInstance.id));
            }

            setLoading(false);
        });

        if (isComputingDetailsModalVisible && currentInstance) {
            Promise.all([
                getInstanceServices(currentInstance.id),
                getInstanceQueueTasks(currentInstance.id),
            ]).then(([services, queueTasks]) => {
                setServices(services);
                setQueueTasks(queueTasks);
            });
        }
    }

    useEffect(() => {
        loadData();

        const interval = setInterval(() => {
            loadData();
        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, [isComputingDetailsModalVisible]);

    if (isLoading) {
        return 'Loading';
    }
      
    const columnsInstances = [
        {
            title: 'Nombre del worker',
            dataIndex: 'worker_name',
            key: 'worker_name',
        },
        {
            title: 'Usuario',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Heartbeat',
            key: 'heartbeat',
            render: (instance) => {
                return (
                    <span>
                        Hace <TimeAgo date={new Date(instance.heartbeat * 1000)} />
                    </span>
                )
            }
        },
        {
            title: 'Estatus',
            key: 'status',
            render: (instance) => {
                if (colors[instance.status]) {
                    return (
                        <Tag color={colors[instance.status]}>
                            {instance.status.toUpperCase()}
                        </Tag>
                    );
                }
                
                return instance.status.toUpperCase();
            }
        },
        {
            title: 'IP Pública',
            dataIndex: 'public_ip',
            key: 'public_ip',
        },
        {
            title: 'ID de instancia',
            dataIndex: 'instance_id',
            key: 'instance_id',
        },
        {
            title: 'Estatus de la emisión',
            key: 'issuance_status',
            render: (instance) => {
                return (
                    <Tag>
                        {issuanceStatusNames[instance.issuance_status]}
                    </Tag>
                );
            }
        },
        {
            title: 'RDP',
            key: 'rdp_file',
            render: (instance) => {
                return (
                    <a href={`${process.env.REACT_APP_PROMESSA_BASE_URL}/monitoring/issuance/rdp/?instanceId=${instance.id}`} target="_blank">
                        Download RDP file
                    </a>
                )
            }
        },
        {
            title: 'Observability',
            key: 'observability',
            render: (instance) => {
                return (
                    <Button 
                        disabled={!instance.computing_observability}
                        type={instance.computing_observability ? 'primary' : 'ghost'}
                        shape="circle" 
                        icon={<EyeOutlined />} 
                        size="medium" 
                        onClick={() => {
                            // Promise.all([
                            //     getInstanceServices(instance.id),
                            //     getInstanceQueueTasks(instance.id),
                            // ]).then(([services, queueTasks]) => {
                            //     setServices(services);
                            //     setQueueTasks(queueTasks);
                                setCurrentInstance(instance);
                                showComputingDetailsModal();

                            //     console.log(services, queueTasks);
                            // });
                        }}
                    />
                );
            }
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (instance) => {
                return (
                    <div>
                        {
                            instance.token && instance.token.status == 0 ?
                            <Button onClick={() => {
                                const result = window.prompt('Ingresar token Citrix');
                                if (result) {
                                    updateToken(instance.token.id, result).then(() => {
                                        loadData();
                                    });
                                }
                            }}>Ingresar token</Button>
                            : ''
                        }
                        <Button onClick={() => {
                            const result = window.confirm('Se eliminará la instancia. ¿Deseas continuar?');
                            if (result) {
                                terminateInstance(instance.id).then(() => {
                                    window.alert('El cambio se verá reflejado en unos minutos.');
                                });
                            }
                        }}>Eliminar</Button>
                        <Button onClick={() => {
                            const result = window.prompt(`El valor de TTL debe ser mayor a ${instance.ttl} ¿Cuál es el nuevo valor?`);
                            if (result && parseInt(result) > instance.ttl) {
                                updateInstance({instanceId: instance.id, ttl: result}).then(() => {
                                    window.alert('ESe actualizó el TTL para la instancia');
                                });
                            }
                        }}>Modificar TTL</Button>
                    </div>
                )
            }
        }
    ];

    return (
        <>
            <p>Instancias de emisión</p>
            <Table dataSource={instances} columns={columnsInstances} />

            <Modal 
                title="Logs"
                width={1024} 
                height={768}
                visible={isComputingDetailsModalVisible} 
                onOk={handleComputingDetailsOk} 
                onCancel={handleComputingDetailsCancel}
                footer={[
                    <Button key="close" onClick={handleComputingDetailsCancel}>
                      Cerrar
                    </Button>,
                    <Button key="refresh" onClick={() => {
                        
                    }}>
                      Refrescar
                    </Button>,
                ]}
            >
                <div>
                    {
                        currentInstance && currentInstance.computing_observability && currentInstance.computing_observability.image
                        ? <img key={currentInstance.computing_observability.hash} src={currentInstance.computing_observability.image} width="100%" />
                        : ''
                    }
                </div>
            </Modal>
        </>
    )
};

export default Instances;
