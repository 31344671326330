import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import 'react-select-2/dist/css/react-select-2.css';
import { getUsers } from '../../services/users';

const SelectUser = ({value, onChange}) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getUsers().then(users => {
            setUsers(users.map(user => {
                return {
                   value: user.id,
                   label: `${user.employee_name} (${user.usuario})` ,
                };
            }));
        })
    }, []);

    return (
        <Select
            name="user"
            value={value}
            options={users}
            onChange={onChange}
        />
    )
};

export default SelectUser;
