import React, { useEffect, useState } from 'react';
import { PageHeader } from 'antd';
import { getFiniquitos } from '../../../services/siniestros/finiquitos';
import GridView from '../../../components/GridView';

const columns = [
    {
        title: 'Siniestro',
        key: 'siniestro',
        render: (record) => record.siniestro,
    },
    {
        title: 'RFC',
        key: 'rfc',
        render: (record) => record.rfc,
    },
    {
        title: 'Archivo',
        key: 'archivo',
        render: (record) => {
            return (
                <a href={`${process.env.REACT_APP_PROMESSA_BASE_URL}/siniestros/finiquitos/download/?id=${record.id}`} target="_blank">
                    {record.archivo}
                </a>
            );
        },
    },
    {
        title: 'Enviado',
        key: 'enviado',
        render: (record) => record.enviado,
    },
];

const routes = [
    { path: 'siniestros', name: 'Siniestros' },
    { path: 'finiquitos', name: 'Finiquitos' },
];

const Finiquitos = () => {
    const [isLoading, setLoading] = useState(true);
    const [finiquitos, setFiniquitos] = useState({});
    const [pagination, setPagination] = useState({});

    function refresh(page = 1) {
        if (page != pagination.page) {
            setLoading(true);
            return getFiniquitos(page).then(response => {
                setFiniquitos(response.data);
                setPagination({
                    page: response.pageNumber,
                    pageSize: response.pageSize,
                    totalCount: response.totalCount,
                });

                setLoading(false);
            });
        }
    }

    useEffect(() => {
        refresh();
    }, []);

    if (isLoading) {
        return 'Loading';
    }

    return (
        <>
            <PageHeader
                title={`Finiquitos`}
                routes={routes}
            />
            <div>
                <GridView 
                    key={`siniestros-finiquitos`} 
                    dataSource={finiquitos} 
                    columns={columns} 
                    pagination={pagination}
                    onChange={({page}) => {
                        refresh(page);
                    }}
                    showSearch={false}
                />
            </div>
        </>
    )
};

export default Finiquitos;
