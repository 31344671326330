import axios from '../../../../lib/promessa-axios';

export function getBetaAssignments() {
    return axios.get('/monitoring/beta-assignments').then(response => response.data.applications);
}

export function createBetaAssignment(serviceId) {
    return axios.post('/monitoring/beta-assignments', {
        service_id: serviceId,
    });
}

export function deleteBetaAssignment(serviceId) {
    return axios.delete(`/monitoring/beta-assignments/${serviceId}`);
}
