import React, { useEffect, useState } from 'react';
import GridView from '../../../../components/GridView';
import { DP_DE_APODERADO, DP_POR_APODERADO } from '../../../../constants/endorsement';

import { getEndorsement } from '../../../../services/service/endorsement';

const columns = [
    {
        title: 'F. Emision',
        key: 'f_emision',
        className: 'text-align-center clickable',
        render: (endorsement) => {
            return endorsement.f_emision;
        }
    },
    {
        title: 'Total',
        key: 'total',
        className: 'text-align-center clickable',
        render: (endorsement) => {
            return endorsement.total;
        }
    },
    {
        title: 'Impresos',
        key: 'impresos',
        className: 'text-align-center clickable',
        render: (endorsement) => {
            return endorsement.impresos;
        }
    },
    {
        title: 'Apoderado',
        key: 'apoderado',
        className: 'text-align-center clickable',
        render: (endorsement) => `${endorsement.apo} ${endorsement.apoNombre}`
    },
    {
        title: '',
        key: 'download',
        className: 'text-align-center clickable',
        render: (endorsement) => {
            return (
                <a target="_blank" href={`${process.env.REACT_APP_PROMESSA_BASE_URL}/api/v1/servicio/endosos/download?type=${DP_DE_APODERADO}&date=${endorsement.f_emision}&claveApoderado=${endorsement.apo}`} onClick={(e) => e.stopPropagation()}>
                    <i className="fas fa-print" style={{color: 'black'}}></i>
                </a>
            )
        }
    },
]

const AgentEndorsement = ({ date }) => {
    const [isLoading, setLoading] = useState(true);
    const [endorsements, setEndorsements] = useState([]);
    const [pagination, setPagination] = useState({});

    const loadEndorsements = (filters, page) => {
        return getEndorsement({type: DP_POR_APODERADO, date, filters, page}).then(response => {
            const { pagination, data: endorsements} = response.data;
            setEndorsements(endorsements);
            setPagination(pagination);
            setLoading(false);
        });
    }

    const onChange = ({filters, page}) => {
        loadEndorsements(filters, page);
    };

    useEffect(() => {
        loadEndorsements();
    }, []);

    return (
        <GridView
            loading={isLoading} 
            dataSource={endorsements} 
            columns={columns} 
            rowKey={(record) => record.apo} 
            onChange={onChange} 
            pagination={pagination}
            onClick={(event, endorsement) => {
                window.location.href = `/#/servicio/endosos/${endorsement.f_emision}/apo/${endorsement.apo}`
            }}
            searchPlaceholder="Clave apoderado"
        />
    )
};

export default AgentEndorsement;
