import axios from '../../../../lib/promessa-axios';

export function getQueueTasks() {
    return axios.get('/monitoring/issuance-queue-task').then(response =>{
        return response.data.queue_tasks;
    });
}

export function updateQueueTask({id, status}) {
    return axios.put(`/monitoring/issuance-queue-task/${id}`, {
        status,
    });
}
