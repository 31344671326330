import React, { useState, useEffect } from "react";
import { Card, Button, Form, Col, InputGroup, Row } from "react-bootstrap";
import { notification } from "antd";
import {
  getComercialEdicion,
  putComercialEdicion,
} from "../../../../../services/comercial";

const Nueva = (props) => {
  const tipo_negocio = "NUEVO";
  const id = props.match.params.id;
  const [validated, setValidated] = useState(false);
  const [comercialEdicion, setComercialEdicion] = useState({
    numero_solicitud_fisica: "",
    poliza: "",
  });

  useEffect(() => {
    if (id === null) {
      setComercialEdicion({ ...comercialEdicion });
    } else {
      getComercialEdicion(id).then((comercialEdicion) => {
        return setComercialEdicion(comercialEdicion);
      });
    }
  }, [id]);

  const handleInputNumeroDeSolicitudChange = (e) => {
    const re = /^([0-9\b]){1,15}$/;
    const { name, value } = e.target;
    if (e.target.value === "" || re.test(e.target.value)) {
      setComercialEdicion({ ...comercialEdicion, [name]: value });
    }
  };

  const handleInputPolizaChange = (e) => {
    const re = /^([A-Z0-9]){1,6}$/;
    const { name, value } = e.target;
    if (e.target.value === "" || re.test(e.target.value)) {
      setComercialEdicion({ ...comercialEdicion, [name]: value });
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (
      comercialEdicion.tipo_negocio === tipo_negocio &&
      comercialEdicion.numero_solicitud_fisica !== ""
    ) {
      form.checkValidity(true);
    }
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);
      event.preventDefault();
      return putComercialEdicion({
        id: id,
        numero_solicitud_fisica: comercialEdicion.numero_solicitud_fisica,
        poliza: comercialEdicion.poliza,
      })
        .then((response) => {
          notification.open({
            message: "Solicitud actualizada",
            description: response.data.message,
          });
          let devoluciones =
            comercialEdicion.url + "/comercial/produccion/devoluciones";
          window.location = devoluciones;
        })
        .catch((err) => {
          notification.open({
            message: "Error al actualizar la solicitud",
            description: "Por favor, inténtelo de nuevo",
          });
        });
    }
  };

  return (
    <>
      <h2>Edición de devolución</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Card>
          <Card.Body>
            <Row>
              <Form.Group as={Col} md="4" controlId="ValidationNumeroSolicitud">
                <Form.Label>Numero de solicitud</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Numero de solicitud"
                    aria-describedby="inputGroupPrepend"
                    required
                    name="numero_solicitud_fisica"
                    value={comercialEdicion.numero_solicitud_fisica}
                    onChange={handleInputNumeroDeSolicitudChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Introduce un numero de solicitud valido.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="ValidationPoliza">
                <Form.Label>Poliza</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    disabled={
                      comercialEdicion.tipo_negocio === tipo_negocio
                        ? true
                        : false
                    }
                    type="text"
                    placeholder="Poliza"
                    aria-describedby="inputGroupPrepend"
                    required
                    name="poliza"
                    value={comercialEdicion.poliza}
                    onChange={handleInputPolizaChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Introduce una poliza valida.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>

            <div className="text-right">
              <div>
                <Button type="submit" className="btn btn-primary mr-2">
                  Guardar
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default Nueva;