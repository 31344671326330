import { useState, useEffect } from 'react';
import { isArray } from 'lodash';

import Loading from '../Loading';
import Forbidden from '../Forbidden';

import axios from '../../lib/promessa-axios';

import { PERMISSION, ROLE } from '../../constants/access_control';

function hasPermission(permission) {
  return axios
    .get(`/permission?name=${permission}`)
    .then((response) => response.data.success)
    .catch(() => false);
}

function hasRole(role) {
  return axios
    .get(`/role/${role}`)
    .then((response) => response.data.success)
    .catch(() => false);
}

const AccessControl = ({
  children,
  permission,
  type = PERMISSION,
  silent = false,
}) => {
  const [loading, setLoading] = useState(true);
  const [forbidden, setForbidden] = useState(false);

  useEffect(() => {
    setLoading(true);
    setForbidden(false);

    if (type === PERMISSION) {
      if (isArray(permission)) {
        // TODO revisar ese then/catch
        return Promise.race(
          permission.map((p) => {
            return hasPermission(permission)
              .then((hasPermission) => {
                if (hasPermission) {
                  return Promise.resolve();
                }

                return Promise.reject();
              })
              .then(() => {
                setForbidden(false);
                setLoading(false);
              })
              .catch(() => {
                setForbidden(false);
                setLoading(false);
              });
          })
        );
      }

      return hasPermission(permission).then((hasPermission) => {
        setForbidden(!hasPermission);
        setLoading(false);
      });
    } else if (type === ROLE) {
      if (isArray(permission)) {
        return Promise.race(
          permission.map((p) => {
            return hasRole(permission)
              .then((hasPermission) => {
                if (hasPermission) {
                  return Promise.resolve();
                }

                return Promise.reject();
              })
              .then(() => {
                setForbidden(false);
                setLoading(false);
              })
              .catch(() => {
                setForbidden(false);
                setLoading(false);
              });
          })
        );
      }

      return hasRole(permission).then((hasPermission) => {
        setForbidden(!hasPermission);
        setLoading(false);
      });
    }
  }, [children]);

  if (loading) {
    if (silent) {
      return "";
    }

    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (forbidden) {
    if (silent) {
      return "";
    }

    return <Forbidden />;
  }

  return <>{children}</>;
};

export default AccessControl;
