import React from 'react';

/**
 * constants
 */
import { DOWNLOAD_ENDORSEMENT } from '../../constants/permission';

/**
 * third party dependencies
 */
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Emision from './emision';

import AccessControl from '../../components/AccessControl';
import { PERMISSION, ROLE } from '../../constants/access_control';

const Servicio = () => {
    return (
        <Router>
            <Switch>
                <Route path="/reportes/emision" exact render={() => {
                    return (
                        <>
                            <AccessControl permission="operacion-tye-coordinador" type={PERMISSION}>
                                <Emision />
                            </AccessControl>
                        </>
                    );
                }}>
                </Route>
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </Router>
    );
};

export default Servicio;
