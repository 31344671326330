import axios from '../../../lib/promessa-axios';

function serialize(obj) {
    const str = [];
    for (let p in obj) {
        if (obj.hasOwnProperty(p) && obj[p]) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    }
      
    return str.join("&");
}

export function getInfo() {
    return axios.get('/servicio/endosos/info');
}

export function getEndorsement({type, filters, page, date, agentKey}) {

    const params = {
        type,
        q: filters,
        page,
        date,
        claveApoderado: agentKey
    };

    const queryParams = serialize(params);

    return axios.get(`/servicio/endosos/?${queryParams}`);
}