import { Card, Button } from 'react-bootstrap';

const AccountantInformation = ({ errors, saving, accountantEmail, onAccountantEmailChange, onSave }) => {
    function errorForField(field) {
        return errors[field] ? errors[field][0] : '';
    }
    
    return (
        <Card>
            <Card.Header>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 className="card-label font-weight-bolder text-dark">Información contable</h3>
                    <div>
                        <Button variant="primary" className="mr-2" onClick={() => onSave()}  disabled={saving}>
                            Guardar
                        </Button>
                        <Button variant="secondary">
                            Cancelar
                        </Button>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Correo contable</label>
                        <div className="col-lg-9 col-xl-6">
                            <input 
                                type="email" 
                                placeholder="Correo contable" 
                                className={`form-control form-control-lg form-control-solid ${errorForField('email') ? 'is-invalid' : ''}`}
                                name="accountant-email" 
                                value={accountantEmail} 
                                onChange={(e) => onAccountantEmailChange(e.target.value)}
                            />
                            <div className="invalid-feedback">{errorForField('email')}</div>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default AccountantInformation;
