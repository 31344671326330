import React, { useState, useEffect } from 'react';
import { getSession } from '../../services/session';
import SessionContext from './index';

const SessionContextProvider = ({ children }) => {

    const [session, setSession] = useState({});

    useEffect(() => {
        getSession().then((session) => {
            setSession(session);
          }).catch((e) => {
              console.log(e, process.env.REACT_APP_PROMESSA_BASE_URL);
              window.location.href = process.env.REACT_APP_PROMESSA_BASE_URL;
          });
    }, []);

    return (
        <SessionContext.Provider value={session}>
            {children}
        </SessionContext.Provider>
    )
}

export default SessionContextProvider;
