import React, { useEffect, useRef, useState } from 'react';
import { Table, Switch, Button, Modal } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import TimeAgo from '../../../components/TimeAgo';
import { getLogs, getWorkers, toggleWorkerProcessing } from '../../../services/monitoring/issuance/workers';

const Workers = () => {
    const [isLoading, setLoading] = useState(true);
    const [workers, setWorkers] = useState([]);
    const [isWorkerLogsModalVisible, setIsWorkerLogsModalVisible] = useState(false);
    const [logs, setLogs] = useState([]);
    const [currentWorker, setCurrentWorker] = useState(null);

    const logsContainerEl = useRef(null);

    const showWorkerLogsModal = () => {
        setIsWorkerLogsModalVisible(true);
    };

    const handleWorkerLogsOk = () => {
        setIsWorkerLogsModalVisible(false);
    };

    const handleWorkerLogsCancel = () => {
        setIsWorkerLogsModalVisible(false);
    };

    const scrollToBottom = () => {
        logsContainerEl.scrollIntoView({ behavior: 'smooth'});
    }

    const loadData = () => {
        Promise.all([
            getWorkers(),
        ]).then(([workers]) => {
            setWorkers(workers);
            setLoading(false);
        });

        if (isWorkerLogsModalVisible && currentWorker) {
            getLogs(currentWorker.worker_name).then(logs => {
                setLogs(logs);
                scrollToBottom();
            })
        }
    }

    useEffect(() => {
        loadData();

        const interval = setInterval(() => {
            loadData();
        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, [isWorkerLogsModalVisible, currentWorker]);

    if (isLoading) {
        return 'Loading';
    }

    const columnsWorkers = [
        {
            title: 'Nombre del worker',
            dataIndex: 'worker_name',
            key: 'worker_name',
        },
        {
            title: 'Heartbeat',
            key: 'last_seen',
            render: (worker) => {
                return (
                    <span>
                        Hace <TimeAgo date={new Date(worker.last_seen * 1000)} />
                    </span>
                )
            }
        },
        {
            title: 'Procesamiento habilitado',
            key: 'processing_enabled',
            render: (worker) => {
                const enabled = worker.processing_enabled == 1;

                return (
                    <Switch 
                        checkedChildren="Habilitado" 
                        unCheckedChildren="Deshabilitado" 
                        checked={enabled} 
                        onClick={() => {
                            toggleWorkerProcessing(worker.id).then(loadData)
                        }}
                    />
                );
            }
        },
        {
            title: 'Logs',
            key: 'logs',
            render: (worker) => {
                return (
                    <Button 
                        type="primary" 
                        shape="circle" 
                        icon={<EyeOutlined />} 
                        size="medium" 
                        onClick={() => {
                            getLogs(worker.worker_name).then(logs => {
                                showWorkerLogsModal();
                                setLogs(logs);
                                setCurrentWorker(worker);
                                scrollToBottom();
                            })
                        }}
                    />
                );
            }
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (worker) => {
                return (
                    <div>
                        <a href="https://bookstack.promessa.mx/books/emision/page/como-reiniciar-el-worker" target="_blank">
                            Reiniciar
                        </a>
                    </div>
                )
            }
        }
    ];

    return (
        <>
            <p>Workers</p>
            <Table dataSource={workers} columns={columnsWorkers} />

            <Modal 
                title="Logs"
                width={1000} 
                visible={isWorkerLogsModalVisible} 
                onOk={handleWorkerLogsOk} 
                onCancel={handleWorkerLogsCancel}
                footer={[
                    <Button key="close" onClick={handleWorkerLogsCancel}>
                      Cerrar
                    </Button>,
                    <Button key="refresh" onClick={() => {
                        getLogs(currentWorker.worker_name).then(logs => {
                            setLogs(logs);
                            scrollToBottom();
                        })
                    }}>
                      Refrescar
                    </Button>,
                ]}
            >
                <div style={{ height: 500, overflowY: 'scroll' }} ref={logsContainerEl}>
                {
                    logs.map(log => {
                        return (
                            <p key={log.id}><strong>[{log.created_at}]</strong> {log.content}</p>
                        )
                    })
                }
                </div>
            </Modal>
        </>
    )
};

export default Workers;
