import axios from '../../lib/promessa-axios';

export function getSession() {
    return axios.get('/session')
        .then(response => {
            if (response.data.success) {
                return response.data.payload;
            }
            
            throw new Error('Error retrieving session');
        });
}