import React, { useEffect, useState } from 'react';
import { Table, Tag, Button } from 'antd';
import { getQueueTasks, updateQueueTask } from '../../../services/monitoring/issuance/queue_task';
import { QUEUE_TASK } from '../../../constants/monitoring';

const QueueTasks = () => {
    const [isLoading, setLoading] = useState(true);
    const [queueTasks, setQueueTasks] = useState([]);

    const loadData = async () => {
        const tasks = await getQueueTasks();
        setQueueTasks(tasks);
        setLoading(false);
    }

    useEffect(() => {
        loadData();

        const interval = setInterval(() => {
            loadData();
        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    if (isLoading) {
        return 'Loading';
    }
      
    const columnsTasks = [
        {
            title: 'Número de solicitud',
            key: 'application_number',
            render: (queueTask) => {
                return queueTask.application_number;
            }
        },
        {
            title: 'Tipo de negocio',
            key: 'business_type',
            render: (queueTask) => {
                return queueTask.business_type;
            }
        },
        {
            title: 'Usuario',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Estatus',
            key: 'queue_task_status',
            render: (queueTask) => {
                return (
                    <Tag>
                        {queueTask.status_name}
                    </Tag>
                );
            }
        },
        {
            title: 'Instancia de emisión',
            key: 'issuance_instance',
            render: (queueTask) => {
                if (queueTask.computing_instance) {
                    return (
                        <span>{queueTask.computing_instance.id_instance} ({queueTask.computing_instance.status}) </span>
                    )
                }

                return 'No asociada';
            }
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (queueTask) => {
                return (
                    <div>
                        <Button onClick={() => {
                            const result = window.confirm('La tarea volverá a estatus "Creada". ¿Deseas continuar?');
                            if (result) {
                                updateQueueTask({id: queueTask.id, status: QUEUE_TASK.ESTATUS_CREADA}).then(() => {
                                    loadData();
                                })
                            }
                        }}>Reintentar</Button>
                    </div>
                )
            }
        },
    ];

    return (
        <>
            <p>Tareas en la cola de emisión</p>
            <Table dataSource={queueTasks} columns={columnsTasks} />
        </>
    )
};

export default QueueTasks;
