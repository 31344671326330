import React from 'react';
import { Breadcrumb } from 'antd';

function buildFullPath(routes) {
    return `/#/${routes.map(r => r.path).join('/')}`;
}

const PageHeader = ({ title, routes }) => {
    return (
        <div style={{display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: 10}}>    
            <h3>{title}</h3>
            <Breadcrumb>
            {
                routes.map(({path, name}, index) => {

                    return (
                        <Breadcrumb.Item key={`${path}-${name}`}>
                            {
                                path
                                ? <a href={buildFullPath(routes.slice(0, index + 1))}>{name}</a>
                                : name
                            }
                        </Breadcrumb.Item>
                    )
                })
            }
            </Breadcrumb>
        </div>
    )
}

export default PageHeader;