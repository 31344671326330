import axios from '../../../../lib/promessa-axios';

export function getInstances() {
    return axios.get('/monitoring/issuance-instance').then(response =>{
        return response.data.instances;
    });
}

export function getInstanceServices(instanceId) {
    return axios.get(`/monitoring/issuance-instance/${instanceId}`).then(response =>{
        return response.data.services;
    });
}

export function getInstanceQueueTasks(instanceId) {
    return axios.get(`/monitoring/instance-queue-task/${instanceId}`).then(response =>{
        return response.data.que_tasks;
    });
}

export function terminateInstance(instanceId) {
    return axios.delete(`/monitoring/issuance-instance/${instanceId}`);
}

export function updateInstance({instanceId, ttl}) {
    return axios.put(`/monitoring/issuance-instance/${instanceId}`, {
        ttl,
    });
}
