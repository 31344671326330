import React from 'react';

/**
 * third party dependencies
 */
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Issuance from './issuance';

import AccessControl from '../../components/AccessControl';
import { PERMISSION } from '../../constants/access_control';

const Servicio = () => {
    return (
        <Router>
            <Switch>
                <Route path="/monitoring/issuance" exact render={() => {
                    return (
                        <>
                            <AccessControl permission="monitoring-issuance" type={PERMISSION}>
                                <Issuance />
                            </AccessControl>
                        </>
                    );
                }}>
                </Route>
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </Router>
    );
};

export default Servicio;
