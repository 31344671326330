import axios from '../../../../lib/promessa-axios';

export function getWorkers() {
    return axios.get('/monitoring/issuance-worker').then(response =>{
        return response.data.workers;
    });
}

export function toggleWorkerProcessing(id) {
    return axios.put(`/monitoring/issuance-worker/${id}`).then(response =>{
        return response.data.worker;
    });
}

export function getLogs(workerName) {
    return axios.get(`/monitoring/worker-log?workerName=${workerName}`).then(response =>{
        return response.data.logs.reverse();
    });
}

export function restartWorker(id) {
    return axios.delete(`/monitoring/issuance-worker/${id}`);
}
