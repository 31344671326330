/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from 'react-router-dom';

import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { getSession } from "../../../../../services/session";

export function QuickUserToggler() {
  const [user, setUser] = useState(null);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  useEffect(() => {
    getSession().then(user => {
      setUser(user);
    })
  }, []);

  if (!user) {
    return '';
  }

  return (
    <>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">{user.email}</Tooltip>}
        >
          <div className="topbar-item">
            <div
              className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <>
                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                  {user.fullName}
                </span>
                <Link to="/profile">
                  <span className="symbol symbol-35 symbol-light-success">
                    {
                      user.image ? <img src={user.image} /> : <span className="symbol-label font-size-h5 font-weight-bold">{user.fullName[0]}</span>
                    }
                  </span>
                </Link>
              </>
            </div>
          </div>
        </OverlayTrigger>
    </>
  );
}
