import { Card, Button } from 'react-bootstrap';

const GeneralInformation = ({ errors, saving,  image, nombre, apaterno, amaterno, onNombreChange, onApaternoChange, onAmaternoChange, onSave }) => {
    function errorForField(field) {
        return errors[field] ? errors[field][0] : '';
    }

    return (
        <Card>
            <Card.Header>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h3 className="card-label font-weight-bolder text-dark">Información general</h3>
                    <div>
                        <Button variant="primary" className="mr-2" onClick={() => onSave()} disabled={saving}>
                            Guardar
                        </Button>
                        <Button variant="secondary">
                            Cancelar
                        </Button>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Avatar</label>
                        <div className="col-lg-9 col-xl-6 symbol symbol-90">
                            <img className="symbol-label" src={image} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Nombre</label>
                        <div className="col-lg-9 col-xl-6">
                            <input 
                                type="text" 
                                placeholder="Nombre" 
                                className={`form-control form-control-lg form-control-solid ${errorForField('nombre') ? 'is-invalid' : ''}`}
                                name="name" 
                                value={nombre} 
                                onChange={(e) => onNombreChange(e.target.value)}
                            />
                            <div className="invalid-feedback">{errorForField('nombre')}</div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Apellido Paterno</label>
                        <div className="col-lg-9 col-xl-6">
                            <input 
                                type="text" 
                                placeholder="Apellido Paterno" 
                                className={`form-control form-control-lg form-control-solid ${errorForField('apaterno') ? 'is-invalid' : ''}`}
                                name="apaterno" 
                                value={apaterno} 
                                onChange={(e) => onApaternoChange(e.target.value)}
                            />
                            <div className="invalid-feedback">{errorForField('apaterno')}</div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Apellido Materno</label>
                        <div className="col-lg-9 col-xl-6">
                            <input 
                                type="text" 
                                placeholder="Apellido Materno" 
                                className={`form-control form-control-lg form-control-solid ${errorForField('amaterno') ? 'is-invalid' : ''}`}
                                name="amaterno" 
                                value={amaterno} 
                                onChange={(e) => onAmaternoChange(e.target.value)}
                            />
                            <div className="invalid-feedback">{errorForField('amaterno')}</div>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default GeneralInformation;
