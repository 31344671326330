import React, { useState, useEffect } from 'react';
import { Table, Input } from 'antd';
import { isFunction } from 'lodash';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

const { Search } = Input;

const GridView = (props) => {
    const { columns, pagination, searchPlaceholder, onChange, showSearch = true, onClick = () => { } } = props;

    const [filters, setFilters] = useState(null);
    const [page, setPage] = useState(pagination.page);

    useEffect(() => {
        onChange({ filters, page });
        // eslint-disable-next-line
    }, [filters, page]);

    const onSearch = value => setFilters(value);

    return (
        <>
            {
                showSearch && (
                    <Row>
                        <Col sm={{ span: 12 }} md={{ span: 6, offset: 6 }}>
                            <Search placeholder={searchPlaceholder} onSearch={onSearch} enterButton style={{ margin: '10px 0' }} />
                        </Col>
                    </Row>
                )
            }
            
            <Table
                {...props}
                columns={
                    columns
                        .filter(column => {
                            if (column.shouldRender === undefined) {
                                return true;
                            }

                            if (isFunction(column.shouldRender)) {
                                return column.shouldRender();
                            }

                            return column.shouldRender;
                        })
                        .map(column => {
                            if (column.dataIndex) {
                                if (column.render) {
                                    return {
                                        ...column,
                                        render: (text, record, index) => column.render(text, record, index),
                                    };
                                }

                                return {
                                    ...column,
                                };
                            }

                            return column;
                        })
                }
                pagination={{
                    current: page, pageSize: pagination.pageSize, total: pagination.totalCount, onChange: (page) => {
                        setPage(page)
                    }
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            onClick(event, record);
                        },
                    }
                }}
            />
        </>
    );
};

export default GridView;
