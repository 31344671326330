import React, { useEffect, useState, useContext } from 'react';
import GridView from '../../../../components/GridView';
import { DOWNLOAD_PROMOTER, DOWNLOAD_PROMESSA, DP_GENERAL, DP_POR_APODERADO } from '../../../../constants/endorsement';
import { PROMESSA } from '../../../../constants/role';

import SessionContext from '../../../../context/session';

import { getEndorsement } from '../../../../services/service/endorsement';

const PromoterEndorsement = () => {

    const session = useContext(SessionContext);

    const [isLoading, setLoading] = useState(true);
    const [endorsements, setEndorsements] = useState([]);
    const [pagination, setPagination] = useState({});

    const columns = [
        {
            title: 'F. Emision',
            key: 'f_emision',
            className: 'text-align-center clickable',
            render: (endorsement) => endorsement.f_emision,
        },
        {
            title: 'Total',
            key: 'total',
            className: 'text-align-center clickable',
            render: (endorsement) => {
                return endorsement.total;
            }
        },
        {
            title: 'Impresos',
            key: 'impresos',
            className: 'text-align-center clickable',
            render: (endorsement) => {
                return endorsement.impresos;
            }
        },
        {
            title: '',
            key: 'download',
            className: 'text-align-center clickable',
            render: (endorsement) => {
                return (
                    <a target="_blank" href={`${process.env.REACT_APP_PROMESSA_BASE_URL}/api/v1/servicio/endosos/download?type=${DP_POR_APODERADO}&date=${endorsement.f_emision}`} onClick={(e) => e.stopPropagation()}>
                        <i className="fas fa-print" style={{color: 'black'}}></i>
                    </a>
                )
            }
        },
    ];

    const loadEndorsements = (filters, page) => {
        setLoading(true);

        return getEndorsement({type: DP_GENERAL, filters, page}).then(response => {
            const { pagination, data: endorsements} = response.data;
            setEndorsements(endorsements);
            setPagination(pagination);
            setLoading(false);
        });
    }

    const onChange = ({filters, page}) => {
        loadEndorsements(filters, page);
    };

    useEffect(() => {
        loadEndorsements();
    }, []);

    return (
        <GridView
            loading={isLoading} 
            dataSource={endorsements} 
            columns={columns} 
            rowKey={(record) => record.f_emision + record.pr} 
            onChange={onChange} 
            pagination={pagination}
            onClick={(event, endorsement) => {
                window.location.href = `/#/servicio/endosos/${endorsement.f_emision}`
            }}
            searchPlaceholder="Fecha de emisión"
        />
    )
};

export default PromoterEndorsement;
