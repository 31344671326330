import React from 'react';
import { PageHeader, Tabs } from 'antd';
import Instances from './instances';
import Workers from './workers';
import Credentials from './credentials';
import QueueTasks from './queue_tasks';
import BetaAssignments from './beta-assignments';

const routes = [
    { path: 'monitoring', name: 'Monitoring' },
    { path: 'issuance', name: 'Issuance' },
];

const { TabPane } = Tabs;

const Issuance = () => {
    return (
        <>
            <PageHeader
                title="Monitoreo de emisión"
                routes={routes}
            />
            <Tabs defaultActiveKey="instances">
                <TabPane tab="Instancias y workers" key="instances">
                    <Instances />
                    <br/>
                    <Workers/>
                </TabPane>
                <TabPane tab="Queue" key="queue">
                    <QueueTasks/>
                </TabPane>
                <TabPane tab="Credenciales" key="credentials">
                    <Credentials/>
                </TabPane>
                <TabPane tab="Asignaciones beta" key="beta-assignments">
                    <BetaAssignments/>
                </TabPane>
            </Tabs>
        </>
    )
};

export default Issuance;
