import React from 'react';

/**
 * third party dependencies
 */
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Finiquitos from './finiquitos';

import AccessControl from '../../components/AccessControl';
import { PERMISSION } from '../../constants/access_control';

const Siniestros = () => {
    return (
        <Router>
            <Switch>
                <Route path="/siniestros/finiquitos" exact render={() => {
                    return (
                        <>
                            <AccessControl permission={['siniestros-finiquitos', 'siniestros-finiquitos-admin']} type={PERMISSION}>
                                <Finiquitos />
                            </AccessControl>
                        </>
                    );
                }}>
                </Route>
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </Router>
    );
};

export default Siniestros;
