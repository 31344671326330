import React, { useEffect, useState } from 'react';
import { Table, Col, Row, DatePicker, PageHeader } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';


import { DP_DE_APODERADO, DP_POR_APODERADO } from '../../../constants/endorsement';
import { getIssuanceReport } from '../../../services/reports/emision';

const columnsIndicador = [
    {
        title: 'Usuario',
        key: 'usuario',
        render: (indicador) => indicador.usuario,
    },
    {
        title: 'Total',
        key: 'total',
        render: (indicador) => indicador.count
    },
];

const columnsIssuance = [
    {
        title: 'Reserva sistema',
        key: 'reserva_sistema',
        render: (indicador) => indicador.reserva_sistema
    },
    {
        title: 'Recibido',
        key: 'recibido',
        render: (indicador) => indicador.recibido
    },
    {
        title: 'Capturado promessa.mx',
        key: 'capturado',
        render: (indicador) => indicador.servicios
    },
];

const routes = [
    { path: 'reportes', name: 'Reportes' },
    { path: 'emision', name: 'Trámite y Emisión' },
];

const Emision = () => {
    const [date, setDate] = useState(moment());
    const [isLoading, setLoading] = useState(true);
    const [report, setReport] = useState({});

    useEffect(() => {
        return getIssuanceReport(date.format('YYYY-MM-DD')).then(report => {
            setReport(report);
            setLoading(false);
        });
    }, [date]);

    if (isLoading) {
        return 'Loading';
    }

    const branches = Object.keys(report);

    return (
        <>
            <PageHeader
                title={`Reporte de trámite y emisión - ${date.format('YYYY-MM-DD')}`}
                routes={routes}
            />
            <div>
                <Row style={{marginBottom: 15}}>
                    <Col span={4} offset={18}>
                        <DatePicker allowClear={false} onChange={(date) => setDate(date)} style={{width: '100%' }} placeholder="Selecciona una fecha" locale={locale} value={date} />
                    </Col>
                </Row>
                {
                    branches.map(branch => {
                        return (
                            <>
                                <h5>
                                    Sucursal {branch}
                                </h5>
                                <Row style={{marginBottom: 15}}>
                                    <Col span={8} offset={2}>
                                        <Table key={`${branch}-indicador`} dataSource={report[branch].indicador_captura} columns={columnsIndicador} pagination={false} />
                                    </Col>
                                    <Col span={8} offset={4}>
                                        <Table key={`${branch}-emision`} dataSource={[report[branch].tramites]} columns={columnsIssuance} pagination={false} />
                                    </Col>
                                </Row>
                            </>
                        )
                    })
                }
            </div>
        </>
    )
};

export default Emision;
