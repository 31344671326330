import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Devolucion from "./devolucion/index";

const produccion = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/comercial/produccion/devolucion">
            <Devolucion/>
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default produccion;