import axios from '../../lib/promessa-axios';

export function search(query) {
    return axios.get(`/servicio/search?query=${query}`)
        .then(response => response.data)
        .catch(e => {
            console.log(e);
            return [];
        });
}
