import React, { useEffect, useState } from 'react';

/**
 * antd components
 */
import { Table, Button, Row, Col } from 'antd';

/**
 * components
 */
import ServiceSearch from '../../../components/ServiceSearch';
import { createBetaAssignment, deleteBetaAssignment, getBetaAssignments } from '../../../services/monitoring/issuance/beta_assignments';

/**
 * this screen will be used to manage the services that are assigned
 * to the uipath des queue
 */
const BetaAssignments = () => {
    const [serviceSearchKey, setServiceSearchKey] = useState(Math.random());
    const [applications, setApplications] = useState([]);
    const [selectedApplications, setSelectedApplications] = useState([]);

    useEffect(() => {
        refresh();
    }, []);

    async function refresh() {
        const applications = await getBetaAssignments();
        setApplications(applications);
    }

    function saveAssignments() {
        return Promise.all(selectedApplications.map(application => createBetaAssignment(application)))
            .then(() => {
                setSelectedApplications([]);
                setServiceSearchKey(Math.random());
                return refresh();
            });
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '25%'
        },
        {
            title: 'Servicio ID',
            dataIndex: 'id_application',
            key: 'id_application',
            width: '25%'
        },
        {
            title: 'Numero de solicitud',
            dataIndex: 'application_number',
            key: 'application_number',
            width: '25%'
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (application) => {
                return (
                    <div>
                        <Button onClick={() => {
                            const result = window.confirm('Se eliminará este servicio de la beta. ¿Deseas continuar?');
                            if (result) {
                                deleteBetaAssignment(application.id).then(() => {
                                    refresh();
                                })
                            }
                        }}>Eliminar</Button>
                    </div>
                )
            },
            width: '25%'
        }
    ];

    return (
        <>
            <Row>
                <Col span={12}>
                    <ServiceSearch key={serviceSearchKey} onChange={setSelectedApplications}/>
                </Col>
                <Col span={4}>
                    <Button type='primary' onClick={saveAssignments}>Agregar</Button>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col span={24}>
                    <Table dataSource={applications} columns={columns} />
                </Col>
            </Row>
        </>
    )
}

export default BetaAssignments;
