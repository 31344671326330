import './App.css';

import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { isEmpty } from 'lodash';

import {Layout} from "./lib/metronic/layout";

import 'antd/dist/antd.css';
import Servicio from './modules/servicio';
import { Aside } from './lib/metronic/layout/components/aside/Aside';
import { Spin } from 'antd';

import SessionContextProvider from './context/session/provider';
import SessionContext from './context/session';
import Profile from './modules/profile';
import Reports from './modules/reports';
import Monitoring from './modules/monitoring';
import Comercial from './modules/comercial'
import Concursos from './modules/concursos';
import Siniestros from './modules/siniestros';

function App() {
  return (
    <SessionContextProvider>
      <SessionContext.Consumer>
        {(session) => {
          if (isEmpty(session)) {
            return <Spin size="large" style={{
              position: 'fixed',
              left: '50%',
              top: '50%'
            }} />
          }

          return (
            <Router>
              <Layout>
                <Aside/>
                <Switch>
                  {/* <Route path="/cobranza">
                    <CobranzaEnteros />
                  </Route>
                  <Route path="/comisiones/facturacion">
                    <ComisionesFacturacion />
                  </Route> */}
                  <Route path="/siniestros">
                    <Siniestros />
                  </Route>
                  <Route path="/concursos">
                    <Concursos />
                  </Route>
                  <Route path="/comercial">
                    <Comercial />
                  </Route>
                  <Route path="/servicio">
                    <Servicio />
                  </Route>
                  <Route path="/profile">
                    <Profile />
                  </Route>
                  <Route path="/reportes">
                    <Reports />
                  </Route>
                  <Route path="/monitoring">
                    <Monitoring />
                  </Route>
                  <Route path="/" exact>
                    <h1 style={{textAlign: 'center'}}>
                      Bienvenido
                    </h1>
                  </Route>
                  <Route render={() => <Redirect to="/" />} />
                </Switch>
              </Layout>
            </Router>
          )
        }}
      </SessionContext.Consumer>
    </SessionContextProvider>
  );
}

export default App;
