import React, { useEffect, useState } from 'react';
import { Input, Table, Button, Modal } from 'antd';

import TimeAgo from '../../../components/TimeAgo';
import { associateCredentialsWithUser, getCredentials, createCredentials, deleteCredentials } from '../../../services/monitoring/issuance/credentials';
import SelectUser from '../../../components/SelectUser';

const Credentials = () => {
    const [isLoading, setLoading] = useState(true);
    const [credentials, setCredentials] = useState([]);
    const [credentialsName, setCredentialsName] = useState('');
    const [isModalVisible, setModalVisible] = useState(false);

    const loadData = () => {
        Promise.all([
            getCredentials(),
        ]).then(([credentials]) => {
            setCredentials(credentials);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadData();

        const interval = setInterval(() => {
            loadData();
        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    const showModal = () => {
        setModalVisible(true);
    };

    const handleModalOk = () => {
        setModalVisible(false);
    };

    const handleModalCancel = () => {
        setModalVisible(false);
    };

    if (isLoading) {
        return 'Loading';
    }

    const columnsCredentials = [
        {
            title: 'Nombre del secret',
            dataIndex: 'secret',
            key: 'secret',
            width: '25%'
        },
        {
            title: 'Usuario',
            key: 'employee_name',
            width: '30%',
            render: (credential) => {
                return (
                    <SelectUser value={{value: credential.id_usuario, label: credential.employee_name}} onChange={(e) => {
                        associateCredentialsWithUser(credential.id, e.value).then(() => {
                            loadData();
                        });
                    }} />
                )
            }
        },
        {
            title: 'Heartbeat',
            key: 'heartbeat',
            render: (instance) => {
                return (
                    <span>
                        Hace <TimeAgo date={new Date(instance.heartbeat * 1000)} />
                    </span>
                )
            }
        },
        {
            title: 'ID de instancia',
            dataIndex: 'aws_instance_id',
            key: 'aws_instance_id',
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (credentials) => {
                return (
                    <div>
                        <Button onClick={() => {
                            const result = window.confirm('Se eliminarán las credenciales. ¿Deseas continuar?');
                            if (result) {
                                deleteCredentials(credentials.id).then(() => {
                                    loadData();
                                })
                            }
                        }}>Eliminar</Button>
                    </div>
                )
            }
        }
    ];

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p>Credenciales de Citrix</p>
                <Button type="primary" onClick={showModal}>Agregar credenciales</Button>
            </div>

            <br/>

            <Table dataSource={credentials} columns={columnsCredentials} />
            <Modal 
                title="Agregar credenciales"
                width={500} 
                visible={isModalVisible} 
                onOk={handleModalOk} 
                onCancel={handleModalCancel}
                footer={[
                    <Button key="close" onClick={handleModalCancel}>
                      Cerrar
                    </Button>,
                    <Button key="save" type="primary" disabled={!credentialsName} onClick={() => {
                        createCredentials(credentialsName).then(() => {
                            setCredentialsName('');
                            loadData();
                            setModalVisible(false);
                        })
                    }}>
                      Agregar
                    </Button>,
                ]}
            >
                <div>
                    <Input value={credentialsName} onChange={(e) => setCredentialsName(e.target.value)}/>
                </div>
            </Modal>
        </>
    )
};

export default Credentials;
