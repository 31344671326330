import { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { HashRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { notification } from 'antd';

import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';
import GeneralInformation from './general-information';
import AccountantInformation from './accountant-information';

import { getProfile, updateProfile } from '../../services/profile';

const routes = [
    { path: 'comisiones', name: 'Perfil' },
];

const GENERAL_INFORMATION = 'general-information';
const ACCOUNTANT_INFORMATION = 'accountant-information';

const Profile = () => {
    const [profile, setProfile] = useState(null);
    const [errors, setErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [selectedItem, setSelectedItem] = useState(GENERAL_INFORMATION);

    useEffect(() => {
        getProfile().then(profile => {
            return setProfile(profile)});
    }, [selectedItem]);

    function onSave() {
        setErrors({});
        return updateProfile({ 
            apaterno: profile.apaterno,
            amaterno: profile.amaterno,
            nombre: profile.nombre,
            accountantEmail: profile.email      
        }).then(response => {
            if ( response.data.status === 200 ) {
                notification.open({
                    message: 'Perfil actualizado',
                    description: response.data.message,
                 });
            }            
        })
        .catch(err => {
            notification.open({
                message: 'Error al actualizar datos del perfil',
                description: 'Por favor, inténtelo de nuevo',
            })
            return console.log(err)
        })
    }
    
    if (!profile) {
        return <Loading />
    }
    return (
        <Fragment>
            <PageHeader
                title="Perfil"
                routes={routes}
            />
            <Row>
                <Col xs={3}>
                    <Card>
                        <Card.Body>
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-60 mr-5">
                                    <img className="symbol-label" src={profile.image} />
                                </div>
                                <div>
                                    <span className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{profile.nombre}</span>
                                    <div className="text-muted">{profile.email}</div>
                                </div>
                            </div>
                            <div className="navi navi-bold navi-hover navi-active navi-link-rounded  mt-10">
                                <div className="navi-item mt-2">
                                    <Link 
                                        className={`navi-link py-4 ${selectedItem == GENERAL_INFORMATION ? 'active' : ''}`} 
                                        to="/profile/general-information" 
                                        onClick={() => setSelectedItem(GENERAL_INFORMATION)}>
                                        <span className="navi-icon mr-2">
                                        </span>
                                        <span className="navi-text font-size-lg">Información general</span>
                                    </Link>
                                </div>
                                {
                                    profile.accountant_information ?
                                    (
                                        <div className="navi-item mt-2">
                                            <Link 
                                                className={`navi-link py-4 ${selectedItem == ACCOUNTANT_INFORMATION ? 'active' : ''}`} 
                                                to="/profile/accountant-information"  
                                                onClick={() => setSelectedItem(ACCOUNTANT_INFORMATION)} >
                                                <span className="navi-icon mr-2">
                                                </span>
                                                <span className="navi-text font-size-lg">Información contable</span>
                                            </Link>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={9}>
                    <Router>
                        <Switch>
                            <Route path="/profile/general-information" exact render={(props) => {
                                return <GeneralInformation 
                                            errors={errors}
                                            
                                            onNombreChange={(value) => {
                                                setProfile({
                                                    ...profile,
                                                    nombre: value,
                                                });
                                            }} 
                                            onApaternoChange={(value) => {
                                                setProfile({
                                                    ...profile,
                                                    apaterno: value,
                                                });
                                            }} 
                                            onAmaternoChange={(value) => {
                                                setProfile({
                                                    ...profile,
                                                    amaterno: value,
                                                });
                                            }} 
                                            onSave={onSave}
                                            saving={saving}
                                            {...profile}
                                        />
                            }}>
                            </Route>
                            <Route path="/profile/accountant-information" exact render={(props) => {
                                if (!profile.accountant_information) {
                                    return '';
                                }
                            
                                return <AccountantInformation 
                                            accountantEmail={profile.accountant_information?.email} 
                                            onAccountantEmailChange={(value) => {
                                                setProfile({
                                                    ...profile,
                                                    accountant_information: {
                                                        ...profile.accountant_information,
                                                        email: value,
                                                    },
                                                });
                                            }}
                                            onSave={onSave}
                                            saving={saving}
                                            errors={errors}
                                        />
                            }}>
                            </Route>
                            <Route render={() => ''} />
                        </Switch>
                    </Router>
                </Col>
            </Row>
        </Fragment>
    );
}

export default Profile;
