import React, { useEffect, useState } from 'react';
import GridView from '../../../../components/GridView';
import { DP_DE_APODERADO } from '../../../../constants/endorsement';

import { getEndorsement } from '../../../../services/service/endorsement';

const AgentSpecificEndorsement = ({ date, agentKey }) => {
    const [isLoading, setLoading] = useState(true);
    const [endorsements, setEndorsements] = useState([]);
    const [pagination, setPagination] = useState({});

    const columns = [
        {
            title: 'F. Emision',
            key: 'f_emision',
            className: 'text-align-center',
            render: (endorsement) => {
                return endorsement.f_emision;
            }
        },
        {
            title: 'Tipo',
            key: 'tipo',
            className: 'text-align-center clickable',
            render: (endorsement) => endorsement.tipo_servicio,
        },
        {
            title: 'Poliza',
            key: 'poliza',
            className: 'text-align-center clickable',
            render: (endorsement) => endorsement.poliza,
        },
        {
            title: 'Asegurado',
            key: 'asegurado',
            className: 'text-align-center clickable',
            render: (endorsement) => endorsement.asegurado,
        },
        {
            title: '',
            key: 'download',
            className: 'text-align-center',
            render: (endorsement) => {
                return (
                    <a target="_blank" href={`${process.env.REACT_APP_PROMESSA_BASE_URL}/api/v1/servicio/endosos/download?id=${endorsement.id}`}>
                        <i className="fas fa-print" style={{color: 'black'}}></i>
                    </a>
                )
            }
        },
    ]

    const loadEndorsements = (filters, page) => {
        return getEndorsement({type: DP_DE_APODERADO, date, agentKey, filters, page}).then(response => {
            const { pagination, data: endorsements} = response.data;
            setEndorsements(endorsements);
            setPagination(pagination);
            setLoading(false);
        });
    }

    const onChange = ({filters, page}) => {
        loadEndorsements(filters, page);
    };

    useEffect(() => {
        loadEndorsements();
    }, []);

    return (
        <GridView
            loading={isLoading} 
            dataSource={endorsements} 
            columns={columns} 
            rowKey={(record) => record.id} 
            onChange={onChange} 
            pagination={pagination}
            searchPlaceholder="Tipo negocio"
        />
    )
};

export default AgentSpecificEndorsement;
