import axios from '../../lib/promessa-axios';

export function getComercialEdicion(id) {
    return axios.get(`/comercial/edicion/${id}`)
        .then(response => {
            return response.data.servicio});
}

export function putComercialEdicion({ id, numero_solicitud_fisica, poliza }) {
    return axios.put(`/comercial/edicion/${id}`, {
        numero_solicitud_fisica,
        poliza,
    });
}