/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { getMenu } from "../../../../../../services/menu";
import * as $ from 'jquery';

export function AsideMenuList({ layoutProps }) {

  function getParentElementUntil($element, desiredTag) {
    if ($element.prop('tagName') == desiredTag) {
      return $element;
    }

    return getParentElementUntil($($element.parent()), desiredTag);
  }

  function toggleExpand(e) {
    e.stopPropagation();
    const $target = getParentElementUntil($(e.target), 'LI');

    $target.toggleClass('menu-item-open');
  }

  function renderParent(item, index) {
    return (
      <li
        key={`menu-parent-${index}`}
        onClick={toggleExpand}
        className={`menu-item menu-item-submenu ${getMenuItemActive(
          "/google-material",
          true
        )}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <div className="menu-link menu-toggle">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
          </span>
          <span className="menu-text">{item.label}</span>
          <i className="menu-arrow" />
        </div>
        <div className="menu-submenu ">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            <li onClick={toggleExpand} className="menu-item  menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text">{item.label}</span>
              </span>
            </li>
            {
              renderMenu(item.items)
            }
          </ul>
        </div>
      </li>
    )
  };

  function renderFinalMenu(item, index) {
    return (
      <li
        key={`menu-final-${index}`}
        className={`menu-item ${getMenuItemActive("/builder", false)}`}
        aria-haspopup="true"
      >
        <div className="menu-link" onClick={() => window.location.href = item.url}>
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
          </span>
          <span className="menu-text">{item.label}</span>
        </div>
      </li>
    );
  }

  function renderMenu(items) {
    return (
      <>
        {
          items.map((item, index) => {
            if (item.items && item.items.length) {
              return renderParent(item, index);
            }

            return renderFinalMenu(item, index);
          })
        }
      </>
    )
  };

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    getMenu().then(menuItems => {
      setMenuItems(menuItems);
    })
  }, []);

  

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"}  menu-item-not-hightlighted`
      : "";
  };

  return (
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      {renderMenu(menuItems)}
    </ul>
  );
}
