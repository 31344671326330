import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../_helpers";
import * as $ from 'jquery';

export function Brand() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
          uiService.config,
          "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div
          className={`brand flex-column-auto ${layoutProps.brandClasses}`}
          id="kt_brand"
          style={{background: '#1E1E2D', zIndex: 9}}
      >
        {/* begin::Logo */}
        <a href={process.env.REACT_APP_PROMESSA_BASE_URL} className="brand-logo">
          <img alt="logo" src="https://chat.promessa.mx/assets/logo.png" style={{width: '85%'}}/>
        </a>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle" onClick={(e) => {
              if ($(document.body).hasClass('kt-primary--minimize')) {
                $(document.body).removeClass('kt-primary--minimize aside-minimize');
                $(document.body).removeClass('aside-minimize');
              } else {
                $(document.body).addClass('kt-primary--minimize');
                $(document.body).addClass('aside-minimize');
              }
            }}>
              <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
              </span>
            </button>
            {/* end::Toolbar */}
            </>
        )}
      </div>
      {/* end::Brand */}
      </>
  );
}
