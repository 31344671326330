import React from 'react';

/**
 * constants
 */
import { DOWNLOAD_ENDORSEMENT } from '../../constants/permission';

/**
 * third party dependencies
 */
import PageHeader from '../../components/PageHeader';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import AccessControl from '../../components/AccessControl';
import PromoterEndorsement from './endorsement/promoter';
import { APODERADO, PROMESSA, PROMOTORIA } from '../../constants/role';
import { ROLE } from '../../constants/access_control';
import AgentEndorsement from './endorsement/agent';
import AgentSpecificEndorsement from './endorsement/agent_specific';

const Servicio = () => {
    return (
        <Router>
            <Switch>
                <Route path="/servicio/endosos" exact render={(props) => {
                    const routes = [
                        { path: 'servicio', name: 'Servicio' },
                        { path: 'endosos', name: 'Impresión de pólizas y endosos' },
                    ];
                    
                    return (
                        <>
                            <PageHeader
                                title="Impresión de pólizas y endosos"
                                routes={routes}
                            />
                            <AccessControl permission={DOWNLOAD_ENDORSEMENT}>
                                <AccessControl permission={[PROMESSA, PROMOTORIA]} type={ROLE} silent={true}>
                                    <PromoterEndorsement />
                                </AccessControl>
                            </AccessControl>
                        </>
                    );
                }}>
                </Route>
                <Route path="/servicio/endosos/:date" exact render={(props) => {
                    const { date } = props.match.params;

                    const routes = [
                        { path: 'servicio', name: 'Servicio' },
                        { path: 'endosos', name: 'Endosos' },
                        { path: date, name: date},
                    ];

                    return (
                        <>
                            <PageHeader
                                title="Impresión de pólizas y endosos"
                                routes={routes}
                            />
                            <AccessControl permission={DOWNLOAD_ENDORSEMENT}>
                                <AccessControl permission={[PROMOTORIA, APODERADO]} type={ROLE} silent={true}>
                                    <AgentEndorsement date={date} />
                                </AccessControl>
                            </AccessControl>
                        </>
                    );
                }}>
                </Route>
                <Route path="/servicio/endosos/:date/apo/:agentKey" exact render={(props) => {
                    const { date, agentKey } = props.match.params;

                    const routes = [
                        { path: 'servicio', name: 'Servicio' },
                        { path: 'endosos', name: 'Endosos' },
                        { path: date, name: date},
                        { path: `apo/${agentKey}`, name: agentKey},
                    ];

                    // TODO validate that only the agent has access to his endorsements
                    return (
                        <>
                            <PageHeader
                                title="Impresión de pólizas y endosos"
                                routes={routes}
                            />
                            <AccessControl permission={DOWNLOAD_ENDORSEMENT}>
                                <AccessControl permission={[PROMOTORIA, APODERADO]} type={ROLE} silent={true}>
                                    <AgentSpecificEndorsement date={date} agentKey={agentKey} />
                                </AccessControl>
                            </AccessControl>
                        </>
                    );
                }}>
                </Route>
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </Router>
    );
};

export default Servicio;
